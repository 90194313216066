import { ApplicationPage } from 'app/types';
import { MarketingSubscriptionSuccess } from 'domains/marketing/components/marketing-subscription-success';

const MarketingSubscriptionSuccessPage: ApplicationPage = () => {
  return <MarketingSubscriptionSuccess />;
};

MarketingSubscriptionSuccessPage.getMainLayoutProps = () => ({
  header: null,
  subheader: null,
  footer: null,
  centered: true,
  size: 'md',
});

export default MarketingSubscriptionSuccessPage;
