import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { MarketingVerifyQrCodeSubscription } from 'entities/marketing/marketing';

type Params = MarketingVerifyQrCodeSubscription;
type Data = {
  customerId: number;
};

const NAME = '/Marketing/VerifyQrCodeSubscription';
export const VerifyQrCodeSubscriptionEndpoint = createModernEndpoint<Params, Data>(NAME);

export default VerifyQrCodeSubscriptionEndpoint;
export type { Data };
