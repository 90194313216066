import { useRouterNavigate, useRouterQuery } from '@swe/shared/providers/router';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { Hero } from '@swe/shared/ui-kit/components/hero';
import { CalendarIllustration } from '@swe/shared/ui-kit/components/illustration';
import Loader from '@swe/shared/ui-kit/components/loader';

import { useEffect } from 'react';

import { Routes } from 'common/router/constants';

import { useVerifyQrCodeSubscription } from 'domains/marketing/use-cases/use-verify-qr-code-subscription';

const MarketingSubscriptionSuccess = () => {
  const query = useRouterQuery();
  const { error, isLoading } = useVerifyQrCodeSubscription((query.signedData as string) ?? null);
  const navigate = useRouterNavigate();

  useEffect(() => {
    if (error) {
      SnackbarService.push({
        type: 'danger',
        message: error.message,
      });
      void navigate(Routes.NotFound);
    }
  }, [error, navigate]);

  if (isLoading) {
    return (
      <Loader
        center
        centered
      />
    );
  }

  if (error) {
    return null;
  }

  const getActions = () => {
    const commonActionProps = {
      children: 'Visit store',
      ariaLabel: 'Visit store',
      block: false,
    };

    if (query.url) {
      return [
        {
          ...commonActionProps,
          onClick: () => window.open(String(query.url)),
        },
      ];
    }
    if (query.signedData) {
      return [
        {
          ...commonActionProps,
          onClick: () => navigate(Routes.Home),
        },
      ];
    }
    return [];
  };
  return (
    <Hero
      illustration={<CalendarIllustration />}
      title="Thank you!"
      content={<div>You have successfully subscribed to the marketing notifications.</div>}
      border={false}
      actions={getActions()}
    />
  );
};

export { MarketingSubscriptionSuccess };
