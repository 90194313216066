import VerifyQrCodeSubscriptionEndpoint from 'endpoints/marketing/verify-qr-code-subscription';

const useVerifyQrCodeSubscription = (signedData: string | null) => {
  const { data, isLoading, error } = VerifyQrCodeSubscriptionEndpoint.useRequest(signedData ? { signedData } : null, {
    notifyWithSnackbar: false,
  });
  return {
    customerId: data?.customerId,
    error,
    isLoading,
  };
};

export { useVerifyQrCodeSubscription };
